<template>
  <header :class="{ open: isActive }" class="header">
    <div class="header__container">
      <div class="header__logo">
        <a href="/"><img src="@/assets/img/logo.svg" alt="logo" /></a>
      </div>
      <div class="header__body">
        <nav class="header__nav">
          <ul class="header__list">
            <li class="header__item">
              <router-link
                to="/token-explorer"
                @click="navShow()"
                class="header__link transition-primary"
                >Token explorer</router-link
              >
            </li>
            <li class="header__item">
              <router-link
                to="/transaction"
                @click="navShow()"
                class="header__link transition-primary"
                >Transaction explorer</router-link
              >
            </li>
            <li class="header__item">
              <router-link
                to="/event"
                @click="navShow()"
                class="header__link transition-primary"
                >Chain explorer</router-link
              >
            </li>
          </ul>
        </nav>
        <div @click="balanceShow()" class="value value--action link--wallet">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40">
            <g id="Слой_2" data-name="Слой 2">
              <g id="Слой_1-2" data-name="Слой 1">
                <g>
                  <path
                    d="M30.34,0H9.66A9.65,9.65,0,0,0,0,9.66V30.34A9.65,9.65,0,0,0,9.66,40H30.34A9.65,9.65,0,0,0,40,30.34V9.66A9.65,9.65,0,0,0,30.34,0ZM28.27,9.09l.59,3.71H12.25L27.18,8.4A.85.85,0,0,1,28.27,9.09Zm3.07,20.36a2.19,2.19,0,0,1-2.19,2.19H11.92A3.64,3.64,0,0,1,8.28,28V12.53a3.09,3.09,0,0,1,3.09-3.09h8l-5.24,1.64H11.37a1.45,1.45,0,0,0-.2,2.89h18a2.18,2.18,0,0,1,2.19,2.18v2.66h-6a4,4,0,0,0,0,8h6ZM30.19,25.6H25.51a2.8,2.8,0,0,1,0-5.6h4.68a2.8,2.8,0,0,1,0,5.6Z"
                  />
                  <circle cx="25.56" cy="22.8" r="0.92" />
                </g>
              </g>
            </g>
          </svg>
        </div>
        <div @click="navShow()" class="header__toggle">
          <div class="header__toggle-wrap">
            <div class="header__hamburger">
              <div class="header__line"></div>
              <div class="header__line"></div>
              <div class="header__line"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
  <Dialog v-model:show="dialogBalanceVisible" class="check-balance">
    <h2>Check Balance</h2>
    <div class="modal-search">
      <Select v-model="selectedBalanceNet" :options="netBalanceOptions" storage-key="selectedTxNet"/>
      <label class="search">
        <input
          v-model="inputBalanceValue"
          type="text"
          placeholder="Rj7J7MiX2bWy8sNyYWQLMM5VpKaY1bzwW3Yo1JLGd5Md1frY8dMWQiW7mdBRncUBnyuaXWnC7bZDTo3R8z71D1LuVbGX1aYzAfncKbaR"
        />
        <button
          @click="checkBalance()"
          class="search-button transition-primary"
          type="button"
          name="button"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="11.819"
            height="11.819"
            viewBox="0 0 11.819 11.819"
          >
            <path
              d="M13.535,12.528,10.91,9.909a4.991,4.991,0,1,0-1,1l2.619,2.625a.712.712,0,0,0,1.006,0h0a.712.712,0,0,0,0-1.006Zm-6.6-2.042a3.532,3.532,0,1,1,3.532-3.532,3.532,3.532,0,0,1-3.532,3.532Z"
              transform="translate(-1.924 -1.924)"
            />
          </svg>
        </button>
      </label>
    </div>
    <div class="modal-body">
      <div v-if="Balance.balance">
        <ul v-if="Balance.balance.length" class="item__body list-info">
          <li class="list-bg" v-for="item in Balance.balance" :key="item.token">
            <ul v-if="item" class="list-info__list">
              <li v-if="item.token">
                <span class="label">Token</span>
                <span class="value">{{ item.token }}</span>
              </li>
              <li v-if="item.coins">
                <span class="label">Coins</span>
                <span class="value">{{ item.coins }}</span>
              </li>
              <li v-if="item.datoshi">
                <span class="label">Coins (datoshi)</span>
                <span class="value">{{ item.datoshi }}</span>
              </li>
              <li v-if="Balance.net">
                <span class="label">Net</span>
                <span class="value">{{ Balance.net }}</span>
              </li>
              <li v-if="Balance.addr">
                <span class="label">Wallet address</span>
                <span class="value">{{ Balance.addr }}</span>
              </li>
            </ul>
          </li>
        </ul>
        <ul v-else class="item__body list-info">
          <li class="list-bg">
            <ul class="list-info__list">
              <li>
                <span class="label">Coins</span>
                <span class="value">Empty</span>
              </li>
              <li v-if="Balance.net">
                <span class="label">Net</span>
                <span class="value">{{ Balance.net }}</span>
              </li>
              <li v-if="Balance.addr">
                <span class="label">Wallet address</span>
                <span class="value">{{ Balance.addr }}</span>
              </li>
            </ul>
          </li>
        </ul>
      </div>
      <ul v-else-if="Balance.error" class="item__body list-info">
        <li class="list-bg">
          <ul class="list-info__list">
            <li>
              <span class="label">Error</span>
              <span class="value">{{ Balance.error.msg }}</span>
            </li>
          </ul>
        </li>
      </ul>
      <ul v-else-if="balanceLoading" class="item__body list-info">
        <li class="list-bg">
          <ul class="list-info__list">
            <li>
              <span class="label">Token</span>
              <span class="value anim"></span>
            </li>
            <li>
              <span class="label">Coins</span>
              <span class="value anim anim-delay-1"></span>
            </li>
            <li>
              <span class="label">Coins (datoshi)</span>
              <span class="value anim anim-delay-2"></span>
            </li>
            <li>
              <span class="label">Net</span>
              <span class="value anim anim-delay-3"></span>
            </li>
            <li class="w100">
              <span class="label">Wallet address</span>
              <span class="value anim"></span>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </Dialog>
  <Hint v-model:show="hintVisible">
    <p>{{ hintText }}</p>
  </Hint>
</template>

<script>
import { ref } from "vue";
import axios from "axios";
import netsAvalible from "@/store/nets.js";

export default {
  name: "Nav",

  data() {
    return {
      // nav
      isActive: false,

      // hint
      hintVisible: false,
      hintText: "Error",

      // balance
      dialogBalanceVisible: false,
      balanceLoading: false,
      selectedBalanceNet: "Backbone",
      netBalanceOptions: [],
      inputBalanceValue: "",
      Balance: [],
    };
  },

  methods: {
    navShow() {
      this.isActive = !this.isActive;
    },

    balanceShow() {
      this.dialogBalanceVisible = !this.dialogBalanceVisible;
    },

    async checkBalance() {
      this.Balance = [];
      this.balanceLoading = true;
      try {
        const response = await axios.get(this.nets.get(this.selectedBalanceNet).url, {
          params: {
            method: "w_addr",
            net: this.selectedBalanceNet,
            addr: this.inputBalanceValue,
            fsn: 1,
          },
        })

        if (response.data && response.data.balance && response.data.balance.length > 0) {
          this.Balance = response.data;      
        } else {
          this.Balance = { balance: [] };
        }
      } catch (error) {
        this.Balance = { balance: [] };
        console.log(error.message);
        this.hintText = error.message;
        this.dialogBalanceVisible = !this.dialogBalanceVisible;
        this.showHint();
      } finally {
        this.balanceLoading = false; 
      }
    },

    showHint() {
      // this.hintMsg = msg;
      this.hintVisible = true;

      setTimeout(() => {
        this.hintVisible = false;
      }, 2000);
    },
  },

  mounted() {
    for (let entry of this.nets) {
      this.netBalanceOptions.push(entry[1].net);
    }
  },

  setup() {
    const nets = ref(netsAvalible);
    return { nets };
  },

  // watch: {
  //   selectedTxNet() {
  //     this.txUrl = this.nets.get(this.selectedTxNet).url
  //     this.txPage.cur = 1
  //     this.selectedSortTxItem = 'new'
  //     this.txItemsCount();
  //   },
  // }
};
</script>

<style>
/*---------------------------------------------------*/
/*	TOPBAR
/*---------------------------------------------------*/

.header {
  /* position: fixed; */
  top: 0;
  left: 0;
  right: 0;
  z-index: 9999;
}

.header__container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 1280px;
  height: auto;
  margin: 0 auto;
  padding: 16px;
  gap: 32px;
  z-index: 9;

  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.header__logo a {
  display: flex;
}

.header__body {
  display: flex;
  align-items: center;
}

.header__nav {
  z-index: 1;

  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.header__list {
  list-style-type: none;
  display: flex;
  margin: 0;
  padding: 0;
  gap: 16px;
}

.header__item {
  position: relative;

  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.header__item.opened {
  opacity: 1;
  visibility: visible;
  left: 0;
}

.header__link {
  text-decoration: none;
  text-align: center;
  display: flex;
  padding: 8px 16px;
  color: var(--color-text-primary);
  /* background: #1D161C 0% 0% no-repeat padding-box; */
  border-radius: 16px;
  cursor: pointer;

  /* -webkit-box-shadow: inset 1px 1px 4px #362D35C9, 2px 2px 7px #0D0A0B71;
	-moz-box-shadow: inset 1px 1px 4px #362D35C9, 2px 2px 7px #0D0A0B71;
	box-shadow: inset 1px 1px 4px #362D35C9, 2px 2px 7px #0D0A0B71; */
  /* -webkit-transition: background cubic-bezier(.17, .67, .5, .71) .1s, color cubic-bezier(.17, .67, .5, .71) .1s;
  transition: background cubic-bezier(.17, .67, .5, .71) .1s, color cubic-bezier(.17, .67, .5, .71) .1s; */
}
.header__link:hover {
  color: var(--color-accent-primary);
  /* color: var(--color-primary);
  background: transparent linear-gradient(260deg, #E3FF59 0%, #D2FF77 100%) 0% 0% no-repeat padding-box;

  -webkit-box-shadow: inset 1px 1px 4px #D3EB8DC9, 2px 2px 7px #0B0A0D71;
	-moz-box-shadow: inset 1px 1px 4px #D3EB8DC9, 2px 2px 7px #0B0A0D71;
	box-shadow: inset 1px 1px 4px #D3EB8DC9, 2px 2px 7px #0B0A0D71; */
}
.router-link-active {
  color: var(--color-accent-primary);
  /* color: var(--color-primary);
  background: transparent linear-gradient(260deg, #E3FF59 0%, #D2FF77 100%) 0% 0% no-repeat padding-box;
  cursor: default;

  -webkit-box-shadow: inset 1px 1px 4px #D3EB8DC9, 2px 2px 7px #0B0A0D71;
	-moz-box-shadow: inset 1px 1px 4px #D3EB8DC9, 2px 2px 7px #0B0A0D71;
	box-shadow: inset 1px 1px 4px #D3EB8DC9, 2px 2px 7px #0B0A0D71; */
}

.header__toggle {
  cursor: pointer;
}
.header__toggle-wrap {
  width: 40px;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.header__hamburger {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 26px;
  height: 20px;
}
.header__line {
  height: 3px;
  background-color: var(--color-text-primary);
  border-radius: 2px;
  z-index: 9999 !important;
}

.open .header__hamburger {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}
.open .header__line {
  display: none;
}
.open .header__line:first-child {
  display: initial;
  position: relative;
  top: 8.5px;
}
.open .header__line:last-child {
  display: initial;
  position: relative;
  top: -8.5px;

  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transform: rotate(90deg);
}

/* .link--arrow:hover img {
  content: url("/img/arrow_external-link_hover.svg");
} */

/* dialog window */
.check-balance h2 {
  text-align: center;
}
.check-balance .dialog__content {
  padding: 16px !important;
}

.modal-search {
  position: relative;
}

.modal-body {
  word-break: break-word;
  word-wrap: break-word;
  overflow-wrap: anywhere;
}

@media only screen and (max-width: 767.7px) {
  .header__logo img {
    width: auto;
    height: 24px;
  }

  .open .header__container {
    background-color: var(--color-primary);
  }

  .open .header__nav {
    display: initial;
    opacity: 1;

    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
  .header__nav {
    position: absolute;
    display: flex;
    top: 66px;
    right: 0;
    left: 0;
    width: 100%;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    overflow: auto;
    background-color: var(--color-primary);
    opacity: 0;

    -webkit-transform: translateY(calc(-100% - 68px));
    -moz-transform: translateY(calc(-100% - 68px));
    -ms-transform: translateY(calc(-100% - 68px));
    -o-transform: translateY(calc(-100% - 68px));
    transform: translateY(calc(-100% - 68px));
    z-index: 9999;
  }
  .header__list {
    flex-direction: column;
    width: 100%;
    padding: 16px;
  }
  .header__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* padding: 8px 16px; */
  }
  .header__link {
    width: 100%;
    justify-content: center;
    border: 0 !important;
  }

  .header__body .link--wallet {
    margin: 0 16px 0 0;
  }
  .header__body .link--wallet,
  .header__body .link--wallet svg {
    width: 28px;
    height: 28px;
  }

  /* dialog window */
  .check-balance h2 {
    margin: 0 0 24px 0;
  }

  .modal-search {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .modal-search .select select,
  .modal-search .search input {
    background-color: var(--color-bg-secondary-dark) !important;
  }
  .modal-search .select:hover select {
    background-color: var(--color-bg-secondary-light) !important;
  }
}

@media only screen and (min-width: 768px) {
  .header__logo img {
    width: auto;
    height: 30px;
  }

  .header__toggle {
    display: none;
  }

  .header__body .link--wallet {
    margin: 0 0 0 32px;
  }
  .header__body .link--wallet,
  .header__body .link--wallet svg {
    width: 36px;
    height: 36px;
  }

  /* dialog window */
  .check-balance h2 {
    margin: 8px 0 32px 0;
  }

  .modal-search .select {
    position: absolute !important;
    top: 4px;
    left: 4px;
    z-index: 1;
  }
  .modal-search .select select {
    width: 175px;
    border-radius: 12px !important;
    box-shadow: none !important;
    padding: 12px 23px 12px 16px !important;
  }
  .modal-search .select:hover select {
    background-color: var(--color-bg-secondary-light) !important;
  }

  .modal-search .search input {
    height: 50px;
    padding: 16px 76px 16px 185px !important;
    background-color: var(--color-bg-secondary-dark) !important;
  }
  .modal-search .search-button {
    top: -8.5px;
    height: 42px;
  }
}
</style>
