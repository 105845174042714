<template>
  <div class="layout token-explorer">
    <h2 class="token-explorer__heading">CF-20 Token Explorer</h2>
    <section class="section token-explorer__section">
      <div class="token-explorer__switch">
        <div
          v-for="net in netOptions"
          :key="net.id"
          :class="[
            'token-explorer__switch-item',
            { 'token-explorer__switch-item--active': net.id === chosenNet.id },
          ]"
          @click="changeChosenNet(net)"
        >
          <p class="token-explorer__switch-text">{{ net.name }}</p>
        </div>
      </div>
      <div class="token-explorer__main-info">
        <div
          class="token-explorer__info-block"
          v-for="token in Object.keys(netData)"
          :key="token"
        >
          <div class="token-explorer__graph-card">
            <div class="token-explorer__info-text">
              <p class="token-explorer__info-title">Token</p>
              <p class="token-explorer__info-value">${{ token }}</p>
              <p class="token-explorer__info-title">Holders</p>
              <p class="token-explorer__info-value">
                {{ formatNumberToDivided(netData[token].holders) }}
              </p>
              <p class="token-explorer__info-title">Staked</p>
              <p class="token-explorer__info-value">
                {{
                  // для CELL в KelVPN согласовано 0
                  chosenNet.id === "1731387916443189248" && token === "CELL"
                    ? "0"
                    : formatTokenAmount(netData[token].staked)
                }}
              </p>
              <p class="token-explorer__info-title">Total Supply</p>
              <p class="token-explorer__info-value">
                {{ formatTokenAmount(netData[token].total_supply) }} ${{
                  token
                }}
              </p>
              <p class="token-explorer__info-title">Circulating Supply</p>
              <p class="token-explorer__info-value">
                {{ formatTokenAmount(netData[token].circulate_supply) }} ({{
                  getPercentsFormattedValue(
                    netData[token].circulate_supply,
                    netData[token].total_supply
                  )
                }}%) ${{ token }}
              </p>
              <!-- <p class="token-explorer__info-title">Burned</p>
            <p class="token-explorer__info-value">565 000 $CELL</p> -->
            </div>
            <div class="token-explorer__info-graph">
              <img
                :src="getTokenImage(token)"
                alt="token image"
                class="token-explorer__info-img"
              />
            </div>
          </div>
          <div class="token-explorer__values-card">
            <p
              class="token-explorer__info-title token-explorer__info-title--small"
            >
              Top holder accounts
            </p>
            <div class="token-explorer__values">
              <div
                v-if="!isSmallScreenMode"
                class="token-explorer__values-table"
              >
                <div
                  class="token-explorer__table-column token-explorer__table-column--first"
                >
                  <p class="token-explorer__info-title">Holder Account</p>
                  <div class="token-explorer__token-info">
                    <div
                      v-for="wallet in wallets[token]"
                      :key="wallet.address"
                      :class="[
                        'token-explorer__val-container',
                        {
                          'token-explorer__val-container--copied':
                            isAddressCopied(wallet.address),
                        },
                      ]"
                      :data-value="wallet.address"
                      @click="copyValue(wallet.address)"
                    >
                      <p class="token-explorer__info-value">
                        {{ wallet.address }}
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  class="token-explorer__table-column token-explorer__table-column--second"
                >
                  <p class="token-explorer__info-title">Total amount</p>
                  <div class="token-explorer__token-info">
                    <p
                      v-for="wallet in wallets[token]"
                      :key="wallet.address"
                      class="token-explorer__info-value"
                    >
                      {{ formatTokenAmount(wallet.balance, true) }} ${{ token }}
                    </p>
                  </div>
                </div>
                <div
                  class="token-explorer__table-column token-explorer__table-column--third"
                >
                  <p class="token-explorer__info-title">Staked</p>
                  <div class="token-explorer__token-info">
                    <p
                      v-for="wallet in wallets[token]"
                      :key="wallet.address"
                      class="token-explorer__info-value"
                    >
                      {{ formatTokenAmount(wallet.staked, true) }} ${{ token }}
                    </p>
                  </div>
                </div>
              </div>
              <div v-else class="token-explorer__values-list">
                <div
                  v-for="wallet in wallets[token]"
                  :key="wallet.address"
                  class="token-explorer__values-item"
                >
                  <p
                    class="token-explorer__info-title token-explorer__info-title_first"
                  >
                    Holder Account
                  </p>
                  <div
                    :class="[
                      'token-explorer__val-container token-explorer__val-container--wallet',
                      {
                        'token-explorer__val-container--copied':
                          isAddressCopied(wallet.address),
                      },
                    ]"
                    :data-value="wallet.address"
                    @click="copyValue(wallet.address)"
                  >
                    <p class="token-explorer__info-value">
                      {{ wallet.address }}
                    </p>
                  </div>
                  <p class="token-explorer__info-title">Total amount</p>
                  <p class="token-explorer__info-value">
                    {{ formatTokenAmount(wallet.balance, true) }} ${{ token }}
                  </p>
                  <p class="token-explorer__info-title">Staked</p>
                  <p class="token-explorer__info-value">
                    {{ formatTokenAmount(wallet.staked, true) }} ${{ token }}
                  </p>
                </div>
              </div>
              <button
                class="token-explorer__values-btn"
                @click="toggleShowAllWallets(token)"
              >
                <img
                  :class="{ rotated: showAllWallets[token] }"
                  src="@/assets/img/chevron.svg"
                  alt="сhevron icon"
                  class="token-explorer__values-icon"
                />
                View first 10
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      explorerUrl: "https://1.api.explorer.cellframe.net",
      netOptions: [
        {
          id: "289391606459531264",
          name: "Backbone",
          tokens: ["CELL", "KEL", "QEVM", "CPUNK", "NYS"],
        },
        {
          id: "1731387916443189248",
          name: "KelVPN",
          tokens: ["KEL", "CELL"],
        },
        // { id: "56797", name: "riemann" },
        // { id: "48059", name: "raiden" },
      ],
      tokenOptions: ["CELL", "KEL"],
      chosenNet: {
        id: "289391606459531264",
        name: "Backbone",
        tokens: ["CELL", "KEL", "QEVM", "CPUNK", "NYS"],
      },
      isSmallScreenMode: false,
      netData: {},
      showAllWallets: {},
      isAllCellWalletsShown: false,
      copiedAddresses: [],
    };
  },
  computed: {
    wallets() {
      return this.chosenNet.tokens.reduce((result, tokenName) => {
        const netData = this.netData[tokenName] || {};
        const balances = netData.max_amount_balance || [];

        result[tokenName] = this.showAllWallets[tokenName]
          ? balances
          : this.isSmallScreenMode
          ? balances.slice(0, 1)
          : balances.slice(0, 3);

        return result;
      }, {});
    },
  },
  mounted() {
    window.addEventListener("resize", this.updateWindowSizeMode);
    this.updateWindowSizeMode();
    this.getPageInfo();
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.updateWindowSizeMode);
  },
  methods: {
    isAddressCopied(val) {
      return this.copiedAddresses.includes(val);
    },
    getTokenImage(token) {
      try {
        return require(`@/assets/img/${token.toLowerCase()}-picture.svg`);
      } catch (e) {
        return require("@/assets/img/cell-picture.svg");
      }
    },
    copyValue(val) {
      navigator.clipboard.writeText(val);
      if (this.copiedAddresses.includes(val)) return;
      this.copiedAddresses.push(val);
      setTimeout(() => {
        this.copiedAddresses = this.copiedAddresses.filter(
          (address) => address !== val
        );
      }, 1000);
    },
    updateWindowSizeMode() {
      this.isSmallScreenMode = window.innerWidth < 768;
    },
    changeChosenNet(net) {
      this.chosenNet = net;
      this.getPageInfo();
    },
    async getPageInfo() {
      const promises = this.chosenNet.tokens.map((token) =>
        this.getTokenInfo(token)
      );
      const netDataArray = await Promise.all(promises);

      this.netData = netDataArray.reduce((result, data, index) => {
        const tokenName = this.chosenNet.tokens[index];
        result[tokenName] = data;
        return result;
      }, {});
    },
    async getTokenInfo(tokenName) {
      // https://1.api.explorer.cellframe.net/token_info/
      // total_address/
      // {net_id}/
      try {
        const { data } = await axios.get(
          `${this.explorerUrl}/token_info/get_info/${this.chosenNet.id}/${tokenName}`
        );
        return data;
      } catch (error) {
        console.log("getTokenInfo error");
        console.log(error);
      }
    },
    formatNumberToDivided(number) {
      if (!number) return 0;
      let [integerPart] = number
        .toLocaleString("en-US", {
          useGrouping: false,
          maximumFractionDigits: 20,
        })
        .split(".");
      // Форматируем целую часть с пробелами между тысячами
      return integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    },
    formatTokenAmount(number, withoutDecimals) {
      // Перевод из датоши (10^18) в токен
      const tokenValue = number / 1e18;
      // Преобразуем в строку с разделением разрядов и дробной частью
      let [integerPart, fractionalPart] = tokenValue
        .toLocaleString("en-US", {
          useGrouping: false,
          maximumFractionDigits: 20,
        })
        .split(".");
      // Форматируем целую часть с пробелами между тысячами
      integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, " ");
      // Сокращаем дробную часть до максимум двух цифр
      if (fractionalPart) {
        const significantDigits = parseFloat("0." + fractionalPart)
          .toPrecision(2) // Оставляем максимум две значимые цифры
          .split(".")[1]; // Берём только дробную часть
        fractionalPart = significantDigits?.replace(/0$/, "") || ""; // Убираем нули в конце
      }
      if (withoutDecimals) {
        return integerPart;
      } else {
        return fractionalPart
          ? `${integerPart}.${fractionalPart}`
          : integerPart;
      }
    },
    formatNumberWithTwoSignificantDecimals(number) {
      // Преобразуем число в строку с максимум двумя значимыми цифрами
      const [integerPart, fractionalPart] = number
        .toLocaleString("en-US", {
          useGrouping: false,
          maximumFractionDigits: 20,
        })
        .split(".");

      if (fractionalPart) {
        // Сокращаем дробную часть до двух значимых цифр
        const significantDigits = parseFloat("0." + fractionalPart)
          .toPrecision(2) // Оставляем максимум две значимые цифры
          .split(".")[1]; // Берём только дробную часть
        const trimmedFractionalPart =
          significantDigits?.replace(/0$/, "") || ""; // Убираем конечный 0
        return `${integerPart}.${trimmedFractionalPart}`; // Собираем итоговое число
      }

      return integerPart; // Если дробной части нет
    },
    getPercentsFormattedValue(dividend, divisor) {
      return this.formatNumberWithTwoSignificantDecimals(
        (dividend / divisor) * 100
      );
    },
    toggleShowAllWallets(tokenName) {
      this.showAllWallets[tokenName] = !this.showAllWallets[tokenName];
      this.isAllCellWalletsShown = !this.isAllCellWalletsShown;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/css/layout.css";
@import "../assets/css/pagination.css";

// css reset
p {
  margin: 0;
}

.token-explorer {
  padding-top: 64px;
  padding-bottom: 160px;

  @media screen and (max-width: 767px) {
    padding-top: 32px;
    padding-bottom: 160px;
  }

  &__heading {
    font-size: 36px;
    line-height: 48px;
    margin-bottom: 64px;

    @media screen and (max-width: 767px) {
      margin-bottom: 32px;
      padding: 0 25px;
      font-size: 36px;
      line-height: 48px;
    }
  }

  &__section {
    max-width: 1006px;
    padding: 0 24px;
    margin-bottom: 0;
  }

  &__switch {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 16px;
  }

  &__switch-item {
    padding: 12px 16px;
    background-color: var(--color-bg-primary);
    border-radius: 16px;
    cursor: pointer;

    &--active {
      background-color: var(--color-bg-secondary-light);
      color: #baff57;
      cursor: default;
    }
  }

  &__switch-text {
    font-size: 18px;
    line-height: 24px;
  }

  &__main-info {
    display: flex;
    flex-flow: column nowrap;
    gap: 64px;
    padding-top: 32px;

    @media screen and (max-width: 767px) {
      gap: 32px;
    }
  }

  &__info-block {
    display: flex;
    flex-flow: column nowrap;
    gap: 32px;
  }

  &__graph-card {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    gap: 32px;

    @media screen and (max-width: 767px) {
      flex-flow: column nowrap;
    }
  }

  &__info-text {
    display: flex;
    flex-flow: column nowrap;
    flex-grow: 1;
    gap: 8px;

    @media screen and (max-width: 767px) {
      gap: 2px;
    }
  }

  &__info-title {
    color: var(--color-text-secondary);
    font-size: 14px;
    line-height: 24px;

    &--small {
      line-height: 20px;
    }
  }

  &__info-title_first {
    @media screen and (max-width: 767px) {
      padding-top: 10px;
    }
  }

  &__val-container {
    position: relative;
    cursor: pointer;

    &:hover {
      color: var(--color-accent-primary);

      &::before {
        display: block;
      }

      &::after {
        background: url("../assets/img/copy-hover.svg") center / contain
          no-repeat;
      }
    }

    @media screen and (max-width: 767px) {
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      align-items: center;
      gap: 8px;
      padding-right: 38px;
      cursor: default;
    }

    &::before {
      content: attr(data-value);
      display: none;
      position: absolute;
      left: 50%;
      top: -35px;
      transform: translateX(-50%);
      padding: 2px 8px;
      font-size: 14px;
      line-height: 24px;
      color: #fff;
      background-color: var(--color-primary);
      border-radius: 6px;

      @media screen and (max-width: 1199px) {
        left: 0;
        transform: unset;
        top: unset;
        bottom: 100%;
      }

      @media screen and (max-width: 850px) {
        width: 100%;
        word-wrap: break-word;
      }

      @media screen and (max-width: 767px) {
        width: 100%;
        left: 50%;
        top: 100%;
        bottom: unset;
        transform: translateX(-50%);
        background-color: rgba(21, 21, 21, 0.8);
      }
    }

    &::after {
      content: "";
      display: block;
      position: absolute;
      right: -38px;
      top: 50%;
      transform: translateY(-50%);
      width: 28px;
      height: 28px;
      background: url("../assets/img/copy.svg") center / contain no-repeat;

      @media screen and (max-width: 767px) {
        right: 0;
      }
    }

    &--copied {
      &::after,
      &:hover::after {
        background: url("../assets/img/copy-copied.svg") center / contain
          no-repeat;
      }
    }
  }

  &__info-value {
    font-size: 18px;
    line-height: 32px;
    overflow: hidden;
    text-overflow: ellipsis;

    @media screen and (max-width: 1023px) {
      white-space: nowrap;
    }
  }

  &__info-graph {
    display: flex;
    flex-flow: column nowrap;
    gap: 16px;
  }

  &__info-img {
    @media screen and (max-width: 1023px) {
      width: 100%;
      height: auto;
    }
  }

  &__values-card {
    display: flex;
    flex-flow: column nowrap;
    gap: 16px;

    @media screen and (max-width: 767px) {
      gap: 8px;
    }
  }

  &__values {
    padding: 32px 32px 30px;
    background-color: var(--color-bg-primary);
    border-radius: 16px;

    @media screen and (max-width: 767px) {
      padding: 6px 16px 16px 16px;
    }
  }

  &__values-list {
    @media screen and (max-width: 767px) {
      display: flex;
      flex-flow: column nowrap;
      gap: 5px;
    }
  }

  &__values-item {
    display: flex;
    flex-flow: column nowrap;
    gap: 8px;

    @media screen and (max-width: 767px) {
      border-top: 1px solid var(--color-border);

      &:first-child {
        border-top: none;
      }
    }
  }

  &__values-table {
    display: grid;
    grid-template-columns: 52% auto auto;
    gap: 67px;

    @media screen and (max-width: 1023px) {
      gap: 32px;
      justify-content: space-between;
    }
  }

  &__values-btn {
    width: 100%;
    padding: 12px 0 0;
    border: none;
    background-color: transparent;
    outline: none;
    font-family: "Blinker", sans-serif;
    font-size: 16px;
    line-height: 24px;
    color: var(--color-text-secondary);
    cursor: pointer;
    display: flex;
    justify-content: center;
    gap: 10px;

    &:hover {
      color: var(--color-accent-primary);

      img {
        filter: brightness(0) saturate(100%) invert(84%) sepia(94%)
          saturate(375%) hue-rotate(31deg) brightness(110%) contrast(115%);
      }
    }
  }

  &__values-icon {
    width: 24px;
    height: 24px;
    transition: transform 0.3s ease;
  }

  &__values-icon.rotated {
    transform: rotate(180deg);
  }

  &__table-column {
    &--first {
      @media screen and (max-width: 1023px) {
        flex-shrink: 1;
      }

      @media screen and (max-width: 767px) {
        flex-shrink: 0;
      }
    }

    &--second,
    &--third {
      @media screen and (max-width: 1023px) {
        width: 126px;
      }

      @media screen and (max-width: 1023px) {
        width: 100%;
      }
    }
  }

  &__table-values {
    display: flex;
    flex-flow: column nowrap;
    gap: 16px;
  }

  &__token-info {
    display: flex;
    flex-flow: column nowrap;
    gap: 8px;
    padding-top: 16px;
  }
}
</style>
